import React from "react";

import { Paragraph1, Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";
import { Heading, HeadingLevel } from "baseui/heading";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";

const Apply = ({ location }) => {
  const [value, setValue] = React.useState(location?.state?.id || "1");
  return (
    <Page
      title="Подать заявку"
      description="Подать заявку в Фонд Развития промышленности Архангельской области"
      path="/frp/apply"
      parent="frp"
    >
      <HeadingLevel>
        <HeadingLevel>
          <Heading styleLevel={4}>Совместные и федеральные программы</Heading>
        </HeadingLevel>
        <Paragraph1>
          Подача заявки по совместным и федеральным программам осуществляется
          онлайн через{" "}
          <StyledLink
            href="https://frprf.ru/informatsiya-dlya-zayaviteley/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Государственную информационную систему промышленности (ГИСП)
          </StyledLink>
        </Paragraph1>
        <HeadingLevel>
          <Heading styleLevel={4}>Региональные программы</Heading>
        </HeadingLevel>
        <RadioGroup
          value={value}
          onChange={(e) => setValue(e.target.value)}
          name="number"
          align={ALIGN.horizontal}
        >
          <Radio value="1">«Приобретение оборудования»</Radio>
          <Radio value="2">«Проекты лесной промышленности»</Radio>
          <Radio value="3">«Промышленные объекты»</Radio>
        </RadioGroup>
        <Paragraph1>
          Для подачи заявки по региональной программе требуется заполнить
          заявление-анкету и направить в Фонд вместе с перечнем документов с
          пометкой ФРП. Перечень документов и бланки приложены ниже.
        </Paragraph1>
        <Label1>Заявление-анкета и резюме проекта</Label1>
        {value === "1" && (
          <>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1R46Hr6jV30rHEBHZX2AqQ8NI9bvvQuyR/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Заявление-анкета по программе «Приобретение оборудования»
              </StyledLink>
            </Paragraph1>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1WoWmsj-_PKFN-gsZoc8gcw1uSX-tHDto/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Резюме проекта по программе «Приобретение оборудования»
              </StyledLink>
            </Paragraph1>
          </>
        )}
        {value === "2" && (
          <>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1tcIqp9Vuq3tyCAOaULvrwxn2SDKsyE1U/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Заявление-анкета по программе «Проекты лесной промышленности»
              </StyledLink>
            </Paragraph1>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1VG_ddAdsjTOGQsps36cKHelbTCBMzZYp/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Резюме проекта по программе «Проекты лесной промышленности»
              </StyledLink>
            </Paragraph1>
          </>
        )}
        {value === "3" && (
          <>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1R8WNdGFjCZTu0FApJGJHC2gkeN7x36EB/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Заявление-анкета по программе «Промышленные объекты»
              </StyledLink>
            </Paragraph1>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/17DyK74e8m5kdjFS-RfV67UW8IEG0LAHo/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Резюме проекта по программе «Промышленные объекты»
              </StyledLink>
            </Paragraph1>
          </>
        )}
        <Label1>Требования к заявителю</Label1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/19Z1Z9ETMvQfxLQLJmIaXk62n8u3XzkOt/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Перечень документов для ИП
          </StyledLink>
        </Paragraph1>

        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1vqfYuRgnt_OIHWjpYvNaXy1ngF3Qch2t/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Перечень документов для юридических лиц
          </StyledLink>
        </Paragraph1>

        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1jcAli6wo0Q_HxWYdbOFfH1lA54Rj1LM8/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Перечень документов для поручителей
          </StyledLink>
        </Paragraph1>

        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/11SNVwsOPC7dHulGnvycuXYDKVgVb8bjK/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Перечень документов по обеспечению займа
          </StyledLink>
        </Paragraph1>
        <Label1>Бланки документов</Label1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1_xmbGThN91X4hOrJcze-DghgPGK4cbjf/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Заявление о расчетном счете
          </StyledLink>
        </Paragraph1>
        {value !== "1" && (
          <Paragraph1>
            <StyledLink
              href="https://drive.google.com/file/d/1hSq8t8p1OY3w1M0o48vRKQtL6ZQKFfvr/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Заявление на льготный период
            </StyledLink>
          </Paragraph1>
        )}
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1TAEOIi2IBdVdwv6Et_IQeAXDfc78U71D/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Согласие на обработку персональных данных
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1jYGzP0-3uF8u7FyoH9fE88rekIFuADGY/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Согласие физического лица на получение отчета в НБКИ
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1_2-ZneBYx68pTwXygpukkyQXZrx4Ruof/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Согласие юридического лица на получение отчета в НБКИ
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1KyC9Tx3L3eEaQudduHpvwfuCFlZ7VPL-/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Справка о сохранении и создании рабочих мест
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1qJaWlNAqHTdL8acVuFKm2xR_5hnglWfC/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Целевой ежеквартальный отчет о реализации проекта
          </StyledLink>
        </Paragraph1>
      </HeadingLevel>
    </Page>
  );
};

export default Apply;
